/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

this.uoblog = this.uoblog || {};
uoblog.agree = Cookies.get('agree') ? Cookies.get('agree') : 'false';
uoblog.ajax_url = jQuery('body').data('ajaxUrl');
(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // object fit polyfill
        objectFitImages();

        // GDPR Banner
        if (Cookies.get('gdpr-banner')) {
          $('.gdpr-banner').hide();
        } else {
          $('.gdpr-banner').show();
        }
        $('.gdpr-banner button.dismiss').on('click', function () {
          $('.gdpr-banner').hide();
          Cookies.set('gdpr-banner', true);
        });

        $('.site-alert').each(function () {
          // check cookies to see if user has already dismissed the alert
          if (Cookies.get('alertVisible-' + $(this).attr('id'))) {
            $(this).hide();
          } else {
            $(this).show();
          }
        });

        $('.site-alert .btn-close').on('click', function () {
          var
            $alert = $(this).closest('.site-alert'),
            cookie = 'alertVisible-' + $alert.attr('id')
            ;
          $alert.hide();
          Cookies.set(cookie, false);
        });

        $('.site-alert .collapser').on('click', function () {
          var $this = $(this);
          $this.closest('.site-alert-content-container').toggleClass('collapsed');
          $this.toggleClass('collapsed');
        });

        // Hide mobile overlay if window is resized above mobile breakpoint
        $(window).on('resize', function () {
          $overlay = $('.overlay');
          $showSearch = $('.show-search');
          if (window.innerWidth > 969) {
            $overlay.hide();
            $showSearch.hide();
          } else {
            if ($('.mobile-nav').is(':visible')) {
              $overlay.show();
              $showSearch.show();
            }
          }
        });

        // Mobile Nav
        $('.mobile-menu-btn, .mobile-close-btn').on('click keydown', function (event) {
          var
            $mobileNav = $('.mobile-nav'),
            $mobileMenuBtn = $('.mobile-menu-btn'),
            $mobileCloseBtn = $('.mobile-close-btn'),
            $header = $('#header'),
            $mainNavWrapper = $('.main-nav-wrapper'),
            $overlay = $('.overlay'),
            $showSearch = $('.show-search')
            ;

          if (event.type === "click" || (event.type === "keydown" && event.key === "Enter")) {
            if ($mobileNav.is(':hidden')) {
              $mobileNav.show();
              $header.css('padding-top', '0');
              $mainNavWrapper.css('padding-top', '10px');
              $overlay.show();
              $mobileNav.animate({ top: '90px' });
            } else {
              $mainNavWrapper.css('padding-top', '0');
              $header.css('padding-top', '10px');
              $overlay.hide();
              $mobileNav.animate({ top: '-350px' });
              $mobileNav.hide();
              $('.mobile-nav-content .expanded').each(function () {
                var $this = $(this);
                $this.removeClass('expanded');
                $this.attr('aria-expanded', 'false');
              });
            }

            if ($mobileMenuBtn.is(':visible')) {
              $mobileCloseBtn.css('display', 'flex');
              $mobileMenuBtn.hide();
              $showSearch.show();
            } else if ($mobileCloseBtn.is(':visible')) {
              $mobileMenuBtn.show();
              $mobileCloseBtn.hide();
              $showSearch.hide();
            }
          }
        });

        // Close all menus when searchbar is focused
        $('.mobile-nav-header .nav-search input').on('focus', function () {
          $('.mobile-nav-content .expanded').each(function () {
            var $this = $(this);
            $this.removeClass('expanded');
            $this.attr('aria-expanded', 'false');
          });
        });

        // Close all menus when esc is pressed
        $(document).on('keydown', function (e) {
          if (e.key === "Escape") {
            var currentEl = document.activeElement;
            $('.nav-primary > .sub-menu, .nav-primary > a, .nav-primary, .menu-btn, .menu-find-deals-container').each(function () {
              $(this).removeClass('expanded');
              $(this).attr('aria-expanded', 'false');
            });
            $('.show-search').hide();
            if (currentEl && currentEl.id === 's-desktop-search') {
              $('.top-search a').focus();
            }
          }
        });

        // Mobile Dropdowns
        $('.mobile-nav .nav-primary > a').on('click', function () {
          $clicked = $(this);

          // Close all other menus
          $('.nav-primary > .sub-menu, .nav-primary, .menu-find-deals-container, .parent-toggle .sub-menu, .menu-btn-wrapper .menu-btn').each(function () {
            var $this = $(this);
            if ($this[0] === $clicked.siblings('.sub-menu')[0] || this === $clicked.parent('.nav-primary')[0]) {
              if (!$this.hasClass('expanded')) {
                $this.addClass('expanded');
                $this.attr('aria-expanded', 'true');
              } else {
                $this.removeClass('expanded');
                $this.attr('aria-expanded', 'false');
                $('.parent-toggle').each(function () {
                  $this.removeClass('expanded');
                  $this.attr('aria-expanded', 'false');
                });
              }
            } else {
              $this.removeClass('expanded');
              $this.attr('aria-expanded', 'false');
              $('.parent-toggle').each(function () {
                $this.removeClass('expanded');
                $this.attr('aria-expanded', 'false');
              });
            }
          });
        });

        // Desktop Nav
        // Dropdown
        $('.main-nav-wrapper .navigation-alt-menu > li > a').on('click', function () {
          $clicked = $(this);

          // Hide searchbar
          $('.show-search').hide();

          // Close all other menus
          $('.sub-menu').each(function () {
            if ($(this)[0] === $clicked.siblings('.sub-menu')[0]) {
              $(this).toggleClass('expanded');
            } else {
              $(this).removeClass('expanded');
            }
          });

          $('.parent-toggle.expanded').toggleClass('expanded');

          $('.sub-menu, .parent-toggle').each(function () {
            if ($(this).hasClass('expanded')) {
              $(this).attr('aria-expanded', 'true');
              $(this).siblings('a').attr('aria-expanded', 'true');
            } else {
              $(this).attr('aria-expanded', 'false');
              $(this).siblings('a').attr('aria-expanded', 'false');
            }
          });
        });

        // Secondary Menu
        $('.parent-toggle').on('click', function () {
          var $this = $(this);
          $this.toggleClass('expanded');
          if ($this.hasClass('expanded')) {
            $this.attr('aria-expanded', 'true');
            $this.children('ul').attr('aria-expanded', 'true');
          } else {
            $this.attr('aria-expanded', 'false');
            $this.children('ul').attr('aria-expanded', 'false');
            $this.children('a').blur();
          }
        });

        var $parentToggleItem = $('.parent-toggle > a');
        $parentToggleItem.on('click', function () {
          $(this).blur();
        });

        // Find Deals
        $('.main-nav-wrapper .menu-btn, .mobile-nav .menu-btn-wrapper').on('click keydown', function (event) {
          if (event.type === "keydown" && event.key === "Enter") {
            event.preventDefault();
          }
          if (event.type === "click" || (event.type === "keydown" && event.key === "Enter")) {

            if (!$(this).parent().parent().hasClass('mobile-nav')) {
              // Hide searchbar only on desktop
              $('.show-search').hide();
            }

            var $findDeals = $('.menu-find-deals-container, .menu-btn');
            if ($findDeals.hasClass('expanded')) {
              $findDeals.removeClass('expanded');
              $findDeals.attr('aria-expanded', 'false');
            } else {
              $findDeals.addClass('expanded');
              $findDeals.attr('aria-expanded', 'true');
            }
          }

          // Close all other menus
          $('.nav-primary > .sub-menu, .nav-primary > a, .nav-primary').each(function () {
            $(this).removeClass('expanded');
            $(this).attr('aria-expanded', 'false');
          });
        });

        // close any open nav when non-nav items are clicked.
        $('body').on('click', function (event) {
          var $target = $(event.target);
          if ($target.closest('nav').length || $target.closest('.navigation-alt-levels').length) {
            return;
          } else {
            $('.menu-item-has-children').removeClass('subnav-open');
            $('.sub-menu').removeClass('d-block');

            // Find Deals
            if ($('.main-nav-wrapper .menu-find-deals-container').is(':visible') && !event.target.classList.contains('menu-btn') && !event.target.classList.contains('menu-btn-wrapper')) {
              $('.menu-find-deals-container, .menu-btn').removeClass('expanded');
            }

            // Primary Nav
            if ($('.nav-primary > .sub-menu.expanded').is(':visible') && event.target.tagName !== 'A') {
              $('.nav-primary > a').attr('aria-expanded', 'false');
              $('.nav-primary > .sub-menu.expanded').attr('aria-expanded', 'false');
              $('.nav-primary > .sub-menu.expanded').removeClass('expanded');
            }
          }
        });
        var $searchTrigger = $('.top-search a');
        // replace parent theme's on click event with our own
        $searchTrigger.off('click').on('click', function (e) {
          e.preventDefault();
          // Close all other menus
          $('.nav-primary > .sub-menu, .nav-primary > a').each(function () {
            $(this).removeClass('expanded');
            $(this).attr('aria-expanded', 'false');
          });

          $('.show-search').slideToggle('fast', function () {
            $('.nav-search input').focus();
          });
        });

        // AJAX FUNCTIONS
        function decodeHtml(html) {
          var txt = document.createElement("textarea");
          txt.innerHTML = html;
          return txt.value;
        }

        function updateResultCount(count) {
          var
            currCount,
            $counter = $('.count-end')
            ;
          if ($counter.length) {
            currCount = parseInt($counter.html(), 10);
            $counter.html(currCount + count);
          }
          return;
        }

        $('a[data-ajax="true"]').on('click', function (event) {
          // set vars
          event.preventDefault();
          var $this = $(this),
            maxPages = parseInt($(this).data('max-pages'), 10),
            options = {
              action: 'ajax_get_posts',
              author: $this.data('author') ? $this.data('author') : '',
              posts_per_page: $this.data('posts-per-page') ? $this.data('posts-per-page') : 9,
              offset: parseInt($this.data('offset'), 10),
              post_types: $this.data('post-type') ? $this.data('post-type') : 'post',
              template: $this.data('template') ? $this.data('template') : $this.data('post-type'),
              omit_tags: $this.data('omit-tags') ? $this.data('omit-tags') : '',
              omit_categories: $this.data('omit-categories') ? $this.data('omit-categories') : '',
              term_id: $this.data('term-id') ? $this.data('term-id') : '',
              park_location: $this.data('park-location') ? $this.data('park-location') : '',
              $homepage: $this.data('homepage')
            };
          if ($this.data('search-term')) {
            options.search_term = $this.data('search-term');
          }

          // If these values are set, only get posts in this Custom Taxonomy
          if ($this.data('post-taxonomy')) {
            options.tax_query = true;
            options.post_taxonomy = $this.data('post-taxonomy');
          }

          $.ajax({
            url: uoblog.ajax_url,
            data: options,
            method: 'POST',
            success: function (i) {
              if (i.result) {
                // apply template to each result
                for (var key in i.result) {
                  var display = '';
                  var post = {
                    'id': i.result[key].id,
                    'title': decodeHtml(i.result[key].title),
                    'link': i.result[key].link,
                    'thumb': i.result[key].thumb,
                    'post_type': i.result[key].post_type,
                    'cat': i.result[key].cat,
                    'site': i.result[key].site,
                    'date': i.result[key].date,
                    'excerpt': decodeHtml(i.result[key].excerpt),
                    'homepage': options.$homepage,
                    'location': i.result[key].location
                  };
                  $('#ajax-target').append(uoblog.templates['grid-post'](post));

                }
                // adjust offset for subsequent ajax calls
                var
                  currPage = $this.data('current-page'),
                  updatedPage = parseInt(currPage, 10) + 1
                  ;

                $this.data('current-page', updatedPage);
                $this.data('offset', updatedPage + 1);
                updateResultCount(i.result.length);

                if ($this.data('current-page') >= maxPages) {
                  $this.hide();
                }
              } else {
                // hide button
                $this.hide();
              }
            },
            error: function (e, i, t) {
              console.log(e);
              console.log(i);
              console.log(t);
            }
          });
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  var oneTrustLoaded = false;
  var oneTrustInterval;
  function oneTrustIcon() {
    // one trust not loaded
    if (!window.OneTrust) {
      return;
    } else {
      oneTrustLoaded = true;
      clearInterval(oneTrustInterval);
      if (window.OneTrust.getGeolocationData().country === 'US') {
        $('#ot-privacy-icon').removeClass('d-none');
      }
    }
  }

  $(window).load(function () {
    oneTrustInterval = setInterval(oneTrustIcon, 500);
  });

  // Custom Filter Dropdowns
  $(document).ready(function () {
    // used to hold active filters, language is set to english by default
    var
      filters = { language: 'english', reset: 1 },
      $pagination = $('.paginated-load-more'),
      useFilters = $pagination.data('use-filters'),
      $resetFiltersBtn = $('.filter-reset-btn'),
      resetBtnDefaultText = $resetFiltersBtn.text(),
      fetchAfterClick = true
      ;

    function setCountEnd(count) {
      var $counter = $('.count-end');
      if ($counter.length) {
        $counter.html(count);
      }
      return;
    }

    function fetchFilteredPosts() {
      // pass selected option to backend
      $.ajax({
        url: du_paginated_loadmore_params.ajaxurl,
        data: {
          'action': 'du_paginated_loadmore',
          'query': du_paginated_loadmore_params.posts,
          'page': du_paginated_loadmore_params.current_page,
          'posts': filters
        },
        type: 'POST',
        success: function (i) {
          var
            $ajaxTarget = $('#ajax-target'),
            $count = $('.count'),
            $total = $('.total')
            ;

          if (i.data && i.html) {
            var
              data = i.data,
              visiblePosts = parseInt(data.posts_per_page)
              ;

            // set the count to 1
            $count.html('1');

            // update the end count if there are less posts than the desired visible amount
            // otherwise, make it the visible amount
            if (data.total_posts < visiblePosts) {
              setCountEnd(data.total_posts);
            } else {
              setCountEnd(visiblePosts);
            }

            // hide the pagination if there isn't more than one page
            if (data.max_num_pages < 2) {
              $pagination.hide();
            } else {
              $pagination.show();
            }

            // update the total counts
            $total.html(data.total_posts);

            // update the posts
            $ajaxTarget.html(i.html);
          } else {
            // hide the pagination
            $pagination.hide();

            // set the count, count end and total results to 0
            $count.html('0');
            setCountEnd(0);
            $total.html('0');

            // show a "no posts found" message
            $ajaxTarget.html('<h3>No posts were found matching these filters</h3>');
          }
        }
      });
    }

    function updateResetFiltersButton() {
      // subtract one from filters count so we don't include the "reset" flag
      var activeFiltersCount = Object.keys(filters).filter(function (key) {
        // if the key is "reset" or the key's value is "all" (meaning no filter should be applied), filter it out
        return key !== 'reset' && filters[key] !== 'all' && filters[key] !== 'relevance';
      }).length;

      if (activeFiltersCount > 0) {
        // set the initial active filter count on the reset button and enable it
        $resetFiltersBtn.removeAttr('disabled');
        $resetFiltersBtn.attr('data-active-filter-count', activeFiltersCount);
        $resetFiltersBtn.text(resetBtnDefaultText + ' (' + activeFiltersCount + ')');
      } else {
        // return the button to a "disabled" state and reset the text
        $resetFiltersBtn.attr('disabled', true);
        $resetFiltersBtn.attr('data-active-filters-count', 0);
        $resetFiltersBtn.text(resetBtnDefaultText);
      }
    }

    // if we should be using filters
    if (useFilters) {
      // add initial filters data to pagination
      $pagination.attr('data-filters', JSON.stringify(filters));
      updateResetFiltersButton();
    }

    // handle toggles
    $('.select-button').on('click', function () {
      var
        $this = $(this),
        expanded = $this.attr('aria-expanded')
        ;

      // user opened the dropdown, so they are trying to filter using the dropdown options
      fetchAfterClick = true;

      $this
        .attr('aria-expanded', expanded === 'false' ? 'true' : 'false')
        .siblings('.select-dropdown').slideToggle('fast')
        ;
    });

    // update "selector" text on selection
    $('.select-dropdown li[role="option"]').on('click', fetchAfterClick, function () {
      var
        $this = $(this),
        $selectDropdown = $this.closest('.select-dropdown'),
        $selectBtn = $selectDropdown.siblings('.select-button'),
        $selectorValue = $('.selected-value', $selectBtn),
        optionValue = $('input', $this).attr('id'),
        optionKey = $('input', $this).attr('name'),
        optionLabel = $('label', $this).text()
        ;

      // set the filter key dynamically
      filters[optionKey] = optionValue;

      // update the reset filters button
      updateResetFiltersButton();

      // set the filter reset flag to 1
      filters.reset = 1;

      // set the button text to the selected option
      $selectorValue.text(optionLabel);

      // add initial filters data to pagination
      $pagination.attr('data-filters', JSON.stringify(filters));

      // close the dropdown
      $selectBtn.attr('aria-expanded', 'false');
      $selectDropdown.slideUp('fast');

      // make fetch call for filtered posts if we want it to happen
      if (fetchAfterClick) {
        fetchFilteredPosts();
      }
    });

    // reset filters button
    $resetFiltersBtn.on('click', function () {
      var filterTypes = ['location', 'content-type', 'language'];

      // make sure the filters don't fetch after reset, we will do this manually so we only have one call
      fetchAfterClick = false;

      // reset filters data and click the "all" option for each of the filter types
      filterTypes.forEach(function (type) {
        var $matchingSelector = $('.select-button[for="' + type + '"]');
        filters[type] = 'all';
        $matchingSelector.siblings('.select-dropdown').find('#all').trigger('click');
      });

      // reset sorting back to "relevance"
      $('#relevance[type="radio"]').trigger('click');

      // fetch the reset posts
      fetchFilteredPosts();

      // update the filter buttons text and state
      updateResetFiltersButton();
    });
  });

})(jQuery); // Fully reference jQuery after this point.
